import React, { useEffect, useState } from "react";
import "./global.css";
import Container from "./Container";
import { css } from "@emotion/react";
import LogofromImagesFolder from "../images/icon.png";
import Title from "./TitleComponent";

const About = () => {
  return (
    <>
      <Container>
        <div css={main}>
          <img
            src={LogofromImagesFolder}
            alt="sumadhra"
            style={{ width: "120px", height: "100px" }}
          />
          <div>
            <Title
              title="Sumadhura Folium"
              size="1.5vw"
              color="#000"
              case="uppercase"
            />
            <p>
              Bangaloreans had always been in high demand for ready-to-move-in
              flats throughout Whitefield. The fact that major MNCs are based
              within Bangalore East localities including Whitefield,
              Maratahalli, and ITPL has contributed to the high demand for
              ready-to-move-in apartments in Whitefield. Whitefield's rise to
              prominence has been both exciting and remarkable. Here are 5
              reasons why 2bhk / 3bhk Available to Take apartments within
              Whitefield, Bangalore were in such high demand.
              <br />
              <br />
              <div>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <b>Return on Investment:</b>
                    <br />
                    Within Whitefield, the average pricing per square foot for
                    just a 2 BHK / 3 BHK premium flat is approximately Rs. 5076.
                    Sumadhura folium, which has similar residence throughout a
                    more focal area, such as Jaynagar as well as Indiranagar,
                    costs around Rs. 21000. – That is a significant difference.
                    It will undoubtedly burn a large hole in anybody's wallet.
                    Whenever it relates to Value, perhaps no other place
                    compares to Whitefield. Almost all understand how gradually
                    property prices in Bangalore rise and long-term investment
                    in regions including Whitefield featuring a promising return
                    on investment could pay off handsomely. Families who have
                    made significant investments in Whitefield may be the most
                    aware of this.
                  </li>
                  <li>
                    <b>Lifestyle Amenities: </b>
                    <br />
                    How many Sumadhura folium has single-family homes or flats
                    in Bangalore's core districts can boast vast grounds
                    featuring panoramic perspectives of the cityscapes, a
                    children's play area, extensive sports/fitness facilities,
                    clubhouses, ample parking spots, and social infrastructure
                    to stay in contact among neighbors? Perhaps very few, if any
                    at all. Are you prepared to begin? Occupy apartments in
                    locations like Whitefield also provide instant possession
                    that is simple to move into, but they also offer a slew of
                    joy-inducing features that make living much easier,
                    convenient as well as serene – a long contrast from the
                    wrinkled and noisy lifestyles we've all become tired of.
                  </li>
                  <li>
                    <b>The standard of living increases noticeably</b> -How many
                    <b>Sumadhura folium</b> has single-family homes or flats in
                    Bangalore's core districts can boast vast grounds featuring
                    panoramic perspectives of the cityscapes, a children's play
                    area, extensive sports/fitness facilities, clubhouses, ample
                    parking spots, and social infrastructure to stay in contact
                    among neighbors? Perhaps very few, if any at all. Are you
                    prepared to begin? Occupy apartments in locations like
                    Whitefield also provide instant possession that is simple to
                    move into, but they also offer a slew of joy-inducing
                    features that make living much easier, convenient as well as
                    serene – a long contrast from the wrinkled and noisy
                    lifestyles we've all become tired of.
                  </li>
                  <li>
                    <b>Proximity:</b> - However many times would we be caught in
                    those dreadful traffic jams that have grown accustomed to
                    them? It was not a concern for the majority of Whitefield
                    inhabitants. Why? Whereas many people take a quick walk to
                    work, most inhabitants who work for these huge MNCs find it
                    very easy to get to collaborate. Whitefield and its environs
                    employ over 60percent of a total of the town's workforce.
                    When considering rush-hour congestion as well as the long
                    journeys many of us undertake to get to a job in Whitefield,
                    residing in or surrounding Whitefield could easily save
                    roughly 2 hrs of commute time. Those days, leisure is
                    certainly money.
                  </li>
                  <li>
                    <b>Immediate Ownership: </b> -
                    <br />
                    Builders always prioritize genuine property purchasers with
                    a high CIBIL score. Although banks may make appealing
                    proposals to such house buyers, the thought of having their
                    property and a redesigned, better, and happier lifestyle has
                    indeed charmed Bangalore's finest modern households.
                    Apartments that are prepared to inhabit are preferred since
                    they provide for a greater grasp of the home's precise
                    elements and the facilities that comes to accompany it
                    despite saving cash on lease as well as other fees.
                  </li>
                </ul>
              </div>
              <b>
                Sumadhura folium offers two and three-bedroom luxury apartments.
              </b>
              <br />
              Choosing pleasure over the protocol as well as convenience over
              Sumadhura folium has every time been a component of possessing
              2bhk as well as 3bhk luxury flats. Absolute fact appeals to
              everybody. Do we, however, have it? It's impossible to tell how
              much assurance is absolute confidence. Here's an attempt to give
              the house buyer a bit (or a lot) more assurance. Yes, we've got
              your behinds. We are living in a period of profound cultural
              change. And with such a mental change, we as a community could
              easily succumb to popular opinion. In all matters worthy,
              unresolved questions are among the most hazardous replies. One
              could write every day regarding what exactly a problem of whether
              or rather to ask may entail. Let's just dismiss them.
              <br />
              <br />
              <b>Which is more better regular flat and a premium apartment?</b>
              <br />
              Luxury. Without a doubt and difficulty. Sumadhura folium provides
              Luxury apartments with a wide range of amenities plus enhanced
              comforts to make life in this area more relaxing and enjoyable.
              Although they are conveniently positioned to make getting to
              employment and education a breeze, staying in these luxurious
              flats is an adventure in and of itself. Complete sporting, health,
              fitness, including social amenities, combined with traditional
              amenities such as storage, round-the-clock surveillance, and
              maintenance, provide for a very enjoyable living.
              <br />
              <br />
              <b>Cost of luxurious apartments </b>
              <br />
              Surprisingly, owning one is extremely affordable. In Sumadhura
              folium luxury flats are a lot more inexpensive than many of us
              think, especially when compared to traditional apartments and
              single-family houses. There is indeed a popular belief about how
              these high-end flats could be so economical. The majority of these
              premium flats are carefully positioned beyond the town's central
              areas. There are significant disparities in land prices and
              construction expenses, which is the fundamental cause of the
              town's elevated expenditures. Though this facilitates a smooth
              trip to the workplace and schooling, it also allows residents to
              enjoy a significantly more calm and easy way of life, especially
              given the city's horrendous traffic congestion.
            </p>
          </div>
        </div>
      </Container>
    </>
  );
};

export default About;

const main = css`
  text-align: center;
  p {
    text-align: justify;
    padding: 10px 20px;
    color: #000;
    letter-spacing: 0.02vw;
    line-height: 1.5;
    font-weight: 450;
    font-size: 16px;
  }
`;
