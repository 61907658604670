import React,{useState, useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";
import {FaRupeeSign} from 'react-icons/fa';
import Modal from './modal';
import { send } from 'emailjs-com';


const Hero = () => {
  const [State, setState] = useState(0);

  useEffect(() => {
    setState(window.innerWidth);
  }, [State]);

  const handelFormSubmit = (e) => {
    e.preventDefault()
    let client_name = e.target.elements.name.value
    let client_email = e.target.elements.email.value
    let phonenumber = e.target.elements.phone.value
    const finalData = {
      Client_name: client_name,
      Client_email: client_email,
      Client_phone_number: phonenumber,
      Project_Name: "Sumadhura Folium",
    }
    send(
      'gmail',
      'template_Be8dpfNK',
      finalData,
      'user_dL8CNNb8AnMR28qE6oOlM'
    )
      .then((template_params) => {
        alert("You will now be redirected.")
      window.location = "/download/";
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
    
  }
  return (
    <div>
      <div css={one}>
       {State > 600 ? <StaticImage
          src="../images/outdoor1.jpg"
          alt="Sumadhura folium"
          placeholder="blurred"
          layout="fullWidth"
          aspectRatio={7.5 / 3}
        /> :
        <StaticImage
          src="../images/outdoor1.jpg"
          alt="Sumadhura folium"
          placeholder="blurred"
          layout="constrained"
          aspectRatio={2 / 3}
        />
        }
      </div>
      <div css={main}>
        <div css={mainOne}>
        <div className="info-box overflow-hidden">
                <span className="pro-status"></span> <span className="pro-title">Sumadhura Folium</span> <span className="pro-add">Whitefield Borewell Road</span> <span className="pro-dev">by Sumadhura Group</span>
                <ul className="pro-spec">
                   <li> <b>Open space</b> : 11 Acres</li>
                    <li> <b>Units</b> : 1100 Units </li>
                  
                    <li> <b>Possession</b> : 2024 </li>
                    <li> <b>Club-house</b> Inside </li>
           
                   
                </ul>
               
                <span className="" style={{fontSize: "15px", width: "100%", background: "transparent", fontWeight: "bold", textAlign: "center", color: "#fff"}}>
                  
                    <span style={{color:"#fff", display: "block",
                        backgroundColor: "#7f8380",
                        background: "linear-gradient(-45deg, #d26c00, #7a8c57, #d26c00, #7a8c57)",
                        backgroundSize: "400% 400%", WebkitAnimation: "Gradient 3s ease infinite",
                        MozAnimation: "Gradient 3s ease infinite", animation: "Gradient 3s ease infinite", fontSize: "15px", padding: "5px"}}>Booking Open
                </span>
                     {/* <span className="offer-bottom" style={{color: "#333", display: "block", borderBottom:"2px solid #d26c00", borderLeft:"2px solid #d26c00", borderRight:"2px solid #d26c00", padding: "4px"}}>
                        
                    </span>  */}
                </span>
                <br/>
                <span className="pro-tag-line">1 BHK, 2 BHK, 2.5 BHK, 3 BHK & 3.5 BHK Starts</span> <span className="pro-price"><FaRupeeSign/>_._ _ Cr* <small>Onwards</small></span>
                <Modal class="btn btn-info micro-form-btn enqModal effetMoveGradient effectScale" title="Enquire Now"/>
            
                <br/>
                <br/>
                <span className="pro-rera"><b>RERA No</b> Updating Soon</span> 
                 <span className="pro-rera"><b>Please do visit our site to explore more about project detail.</b></span><br/>
            </div>
        </div>
        <div>
        <div class="info-box-form overflow-hidden">
                
                <span class="pro-status"></span> <span class="pro-title1">Pre-Register here for Best Offers</span> 
            
                <hr/>
                <form name="price-popup" method="POST" role="form" class="form-side" onSubmit={handelFormSubmit}>
                    <div class="form-group"><input type="text" name="name" pattern="[a-zA-Z ]+" required class="form-control rounded-0 micro-form-field" required placeholder="Name"/></div>
                    <div class="form-group">
                    <input name="phone" class="form-control rounded-0 micro-form-field" required placeholder="Mobile Number" minLength="10"
                    maxLength="20"
                    title="Must have 10 digit numbers"
                    pattern="^\d{10}$" />
                    </div>
                    <div class="form-group"><input type="email" name="email" class="form-control rounded-0 micro-form-field" required placeholder="E-Mail Address"/></div>
                    <br/>
                    <button type="submit" name="enq_formBtn" class="btn btn-info micro-form-btn effetMoveGradient" value="proceed">Pre-Register Now</button>
                </form>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

const one = css`
  position: relative;
`;

const main = css`
  position: absolute;
  position: absolute;
  width: 100%;
  top: 10vh;
  display: grid;
  grid-template-columns: 6fr 6fr;
  @media(max-width:600px){
    display:block;
  }
`;

const mainOne = css`
  width: 30vw;
  top: 69px;
  left: 10px;
  background-color: #ffffffb8;
  color: #000;
  text-align: center;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: 400;
  margin:2vh;
  margin-left:5vh;
  @media(max-width:600px){
    width:77%;
  }
`;
